/* eslint-disable max-len */
import React from 'react';

interface FlagDEProps {
    /**
     * A string representing the CSS class to be applied to the FlagDEIcon element to override its default styling.
     */
    className?: string;
    /**
     * A number or string representing the height of the FlagDEIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the FlagDEIcon element.
     */
    width?: number | string;
}

/**
 * The `FlagDE` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `FlagDEProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the FlagDEIcon element to override its default styling.
 * @param props.height    A number or string representing the height of the FlagDEIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the FlagDEIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const FlagDEComponent = <FlagDE className="myClass" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const FlagDE = React.forwardRef<SVGSVGElement, FlagDEProps>((props, ref) => {
    const {className, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 5 3"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M0 0h5v3H0z" />
            <path d="M0 1h5v2H0z" fill="#D00" />
            <path d="M0 2h5v1H0z" fill="#FFCE00" />
        </svg>
    );
});

FlagDE.displayName = 'FlagDE';
FlagDE.defaultProps = {
    className: '',
    height: 24,
    testId: 'FlagDE',
    width: 24
};