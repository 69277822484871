type Size = 'lg' | 'md' | 'sm' | 'xs';

interface GetFontSizeParams {
    /** The optional size parameter indicating the desired font size ('xs', 'sm', 'md', or 'lg'). */
    $size?: Size;
}

/**
 * Retrieves the font size based on the provided size parameter.
 * This function returns the appropriate font size value based on the specified size parameter.
 *
 * @param args       The function arguments.
 * @param args.$size The size parameter indicating the desired font size ('xs', 'sm', 'md', or 'lg').
 * @returns The corresponding font size in rem.
 */
export const getFontSize = ({$size}: GetFontSizeParams) => {
    if ($size === 'xs') {
        return '1.2rem';
    }

    if ($size === 'sm') {
        return '1.4rem';
    }

    if ($size === 'lg') {
        return '2rem';
    }

    return '1.6rem';
};

interface GetDesktopFontSizeParams {
    /** The optional size parameter indicating the desired font size ('xs', 'sm', 'md', or 'lg'). */
    $size?: Size;
}

/**
 * Retrieves the desktop font size based on the provided size parameter.
 * This function returns the appropriate font size value for desktop based on the specified size parameter.
 *
 * @param args       The function arguments.
 * @param args.$size The size parameter indicating the desired font size ('xs', 'sm', 'md', or 'lg').
 * @returns The corresponding font size value in rem for desktop.
 */
export const getDesktopFontSize = ({$size}: GetDesktopFontSizeParams) => {
    if ($size === 'xs') {
        return '1.4rem';
    }

    if ($size === 'sm') {
        return '1.6rem';
    }

    if ($size === 'lg') {
        return '2.4rem';
    }

    return '2rem';
};

interface GetLineHeightParams {
    /** The optional size parameter indicating the desired font size ('xs', 'sm', 'md', or 'lg'). */
    $size?: Size;
}

/**
 * Retrieves the line height based on the provided size parameter.
 * This function returns the appropriate line height value based on the specified size parameter.
 *
 * @param args       The function arguments.
 * @param args.$size The size parameter indicating the desired font size ('xs', 'sm', 'md', or 'lg').
 * @returns The corresponding line height as a unitless value as a string.
 */
export const getLineHeight = ({$size}: GetLineHeightParams) => {
    if ($size === 'lg') return '1.4';

    return '1.5';
};