import {Col, media, Row, Spacer, spacing, translucify} from '@nfq/react-grid';
import {AnimatePresence, m as motion} from 'framer-motion';
import Link from 'next/link';
import {Trans, useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {NewsletterForm} from 'UI/components/action/ultra/NewsletterForm/NewsletterForm';
import {Text} from 'UI/components/layout/ultra/Text';

import {SuccessAnimation} from 'UI/animations/ultra';
import {useSuccessAnimation} from 'UI/modules/ultra/hooks/useSuccessAnimation';

/**
 * The Newsletter component.
 *
 * @returns A React element representing the `Newsletter` component.
 */
export const Newsletter = () => {
    const {t} = useTranslation('ultra', {keyPrefix: 'newsletter'});
    const {onSuccess, subscriptionState} = useSuccessAnimation();

    return (
        <Wrapper>
            <StyledRow
                align={{xs: 'flex-start'}}
                direction={{lg: 'row', xs: 'column'}}
                justify={{lg: 'flex-start', xs: 'center'}}
            >
                <Col direction="row" lg={6} offset={{xl: 2}} xl={4}>
                    <StyledText>{t('intro')}</StyledText>
                </Col>
                <Spacer maxX={{lg: 20, xs: 0}} x={{xl: 20, xs: 0}} y={{xl: 0, xs: 6}} isInline />
                <FullWidthCol direction={{xs: 'column'}} justify={{md: 'flex-start', xs: 'center'}} lg={6} xl={4}>
                    <AnimatePresence initial={false}>
                        {subscriptionState === 'pending' ? (
                            <motion.div
                                key="pending"
                                animate="animate"
                                exit="exit"
                                initial="initial"
                                variants={SuccessAnimation}
                            >
                                <NewsletterForm onSuccess={onSuccess} />
                                <Spacer y={3} />
                                <StyledInfo>
                                    <Trans
                                        components={{
                                            newsletter: <StyledLink
                                                href={t('privacyUrl')}
                                                rel="noreferrer"
                                                target="_blank"
                                            />
                                        }}
                                        i18nKey="inputDescription"
                                        t={t}
                                    />
                                </StyledInfo>
                            </motion.div>
                        ) : (
                            <motion.div
                                key="success"
                                animate="animate"
                                exit="exit"
                                initial="initial"
                                variants={SuccessAnimation}
                            >
                                <BiggerText>{t('newsletterSuccessHeadline')}</BiggerText>
                                <Text>{t('newsletterSuccessDescription')}</Text>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </FullWidthCol>
            </StyledRow>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: ${spacing(10)} ${spacing(4)};

    ${media('md')} {
        padding: ${spacing(10)} ${spacing(15)};
    }

    ${media('xl')} {
        padding: ${spacing(30)} ${spacing(4)};
    }
`;

const StyledRow = styled(Row)`
    gap: 0;
    max-width: 168rem;
`;

const FullWidthCol = styled(Col)`
    position: relative;
    width: 100%;
`;

const StyledLink = styled(Link)`
    border: .1rem solid transparent;
    border-radius: .4rem;
    color: ${({theme}) => translucify(theme.colors.primaryActionFontColor, 50)};
    font-family: ${({theme}) => theme.fonts.Dinot};
    outline: none;
    transition: color 300ms ease-in-out;

    &:hover {
        color: ${({theme}) => translucify(theme.colors.primaryActionFontColor, 100)};
    }

    &:focus-visible {
        border-color: ${({theme}) => theme.colors.ultraFocusColor};
    }
`;

const StyledText = styled(Text)`
    color: ${({theme}) => theme.colors.primaryActionFontColor};
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.3;
    text-transform: uppercase;

    ${media('md')} {
        font-size: 3.2rem;
    }
`;

const StyledInfo = styled(Text)`
    color: ${({theme}) => translucify(theme.colors.primaryActionFontColor, 50)};
    font-size: 1.4rem;
    line-height: 1.3;
`;

const BiggerText = styled(Text)`
    color: ${({theme}) => theme.colors.primaryActionFontColor};
    font-size: 2rem;
`;