import type React from 'react';
import {useState} from 'react';

import {setCookie} from 'cookies-next';
import {useRouter} from 'next/router';

import {FlagDE} from 'Images/icons/FlagDE';
import {FlagEN} from 'Images/icons/FlagEN';

interface LanguageInterface {
    icon: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
    id: string;
    key: string;
}

export type availableLanguagesType = 'de' | 'en';

const availableLanguagesObject = {
    de: {
        icon: FlagDE,
        id: 'de',
        key: 'DE'
    },
    en: {
        icon: FlagEN,
        id: 'en',
        key: 'EN'
    }
};

/**
 * The hook for the language switch component. It provides the necessary logic to handle the language
 * selection and display the available options to the user.
 * This hook is used in the `LanguageSwitch` component to manage the state of the language dropdown menu
 * and the currently selected language.
 *
 * @example
 * ```tsx
 * const {
 *    availableLanguages,
 *    changeLanguage,
 *    dropdownIsShowing,
 *    selectedLanguage,
 *    toggleLanguageDropdown
 * } = useLanguageSwitch();
 * ```
 *
 * @returns An object containing the necessary properties and functions to manage the language switch component.
 */
export const useLanguageSwitch = () => {
    const router = useRouter();
    const [dropdownIsShowing, setDropdownIsShowing] = useState(false);
    const selectedLanguage: LanguageInterface = availableLanguagesObject[router.locale as availableLanguagesType];
    const availableLanguages = Object.values(availableLanguagesObject).filter(lang => lang.id !== selectedLanguage.id);

    /**
     * Toggles the visibility state of a language dropdown menu. This function inverses the current
     * visibility state of the dropdown menu, making it visible if it was previously hidden and vice
     * versa.
     * This approach enhances the user interface by providing a seamless way to access and
     * retract the language selection options.
     *
     * @example
     * ```ts
     * <button onClick={toggleLanguageDropdown}>Toggle Language</button>
     * ```
     */
    const toggleLanguageDropdown = () => {
        setDropdownIsShowing(!dropdownIsShowing);
    };

    /**
     * Changes the language of the application and updates the cookie to store the user's preference.
     * This function is triggered when a user selects a language, updating the application's language
     * and storing the user's preference in a cookie. It uses the `setCookie` function from the `cookies-next`
     * package to set the `NEXT_LOCALE` cookie with the selected language, ensuring that the user's preference
     * is persisted across sessions.
     *
     * @param e The language selected by the user, expected to be a key from `availableLanguagesType`.
     * @example
     * ```ts
     * changeLanguage('en');
     * ```
     */
    const changeLanguage = async (e: availableLanguagesType) => {
        setCookie('NEXT_LOCALE', e);
        toggleLanguageDropdown();

        await router.push(router.asPath, router.asPath, {locale: e});
    };

    return {
        availableLanguages,
        changeLanguage,
        dropdownIsShowing,
        selectedLanguage,
        toggleLanguageDropdown
    };
};