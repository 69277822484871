import type {ReactNode} from 'react';
import {useId} from 'react';

import {media, spacing, translucify} from '@nfq/react-grid';
import styled from 'styled-components';

import {P} from 'UI/components/layout/Text';

interface InputProps {
    /**
     * The error message to be displayed below the input, if there is one.
     */
    error?: ReactNode;

    /**
     * The name attribute for the input field.
     */
    name?: string;

    /**
     * The placeholder text for the input.
     */
    placeholder?: string;

    /**
     * The label for the submit button in the input field.
     */
    submitLabel: string;

    /**
     * The type of the input. Can either be 'email' or 'text'.
     */
    type: 'email' | 'text';
}

/**
 * The input component for the CCT EVO Ultra landing page. This component renders an input field along with a submit
 * button, optionally displaying an error message below the input.
 *
 * @param props             The component props.
 * @param props.error       The error message to be displayed below the input, if there is one.
 * @param props.name        The name attribute for the input field.
 * @param props.placeholder The placeholder text for the input.
 * @param props.submitLabel The label for the submit button in the input field.
 * @param props.type        The type of the input. Can either be 'email' or 'text'.
 * @returns The rendered input component.
 *
 * @example
 * ```tsx
 * <Input
 *     error="Invalid email"
 *     name="email"
 *     placeholder="Enter your email"
 *     submitLabel="Submit"
 *     type="email"
 * />
 * ```
 */
export const Input = ({error, name, placeholder, submitLabel, type}: InputProps) => {
    const errorContainerId = useId();
    const hasError = Boolean(error);

    return (
        <>
            <Wrapper>
                <InputField
                    aria-errormessage={hasError ? errorContainerId : undefined}
                    aria-invalid={hasError}
                    name={name}
                    placeholder={placeholder}
                    type={type}
                />
                <Button type="submit">{submitLabel}</Button>
            </Wrapper>
            {hasError && <Error id={errorContainerId}>{error}</Error>}
        </>
    );
};

const Wrapper = styled.div`
    background: ${({theme}) => translucify(theme.colors.ultraPageForeground, 5)};
    border: 1px ${({theme}) => translucify(theme.colors.ultraPageForeground, 30)} solid;
    border-radius: 1.2rem;
    display: inline-flex;

    &:focus-within {
        border-color: ${({theme}) => theme.colors.ultraPageForeground};
    }

    &:has(:user-invalid),
    &:has([aria-invalid="true"]) {
        border-color: ${({theme}) => theme.colors.ultraDangerColor};
    }
`;

const InputField = styled.input`
    background: transparent;
    border: 0 none;
    color: ${({theme}) => theme.colors.ultraPageForeground};
    flex-grow: 1;
    font: 1.6rem / 1 ${({theme}) => theme.fonts.Dinot};
    outline: 0 none;
    padding: ${spacing(5)} 0 ${spacing(5)} ${spacing(6)};

    &:placeholder-shown {
        color: ${({theme}) => translucify(theme.colors.ultraPageForeground, 50)};
    }

    ${media('lg')} {
        font-size: 2rem;
    }
`;

const Button = styled.button`
    background: transparent;
    border: .1rem solid transparent;
    border-radius: 1.2rem;
    color: ${({theme}) => theme.colors.ultraPageForeground};
    cursor: pointer;
    font: 700 1.6rem / 1 ${({theme}) => theme.fonts.Dinot};
    outline: none;
    padding: ${spacing(5)} ${spacing(6)};
    text-transform: uppercase;

    ${media('lg')} {
        font-size: 2rem;
    }

    &:focus-visible {
        border-color: ${({theme}) => theme.colors.ultraFocusColor};
    }

    &:hover {
        color: ${({theme}) => translucify(theme.colors.ultraPageForeground, 60)};
    }
`;

const Error = styled(P)`
    color: ${({theme}) => theme.colors.ultraDangerColor};
    font: 1.6rem / 1 ${({theme}) => theme.fonts.Dinot};
    padding: ${spacing(2)} ${spacing(4)};
`;