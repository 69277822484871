import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {DropdownTransition} from 'UI/animations/ultra';

import type {WithChildren} from 'types/global';

/**
 * `Dropdown` is a generic component designed to render a dropdown UI element using a styled
 * container (`StyledDropdown`) that applies animations for transitions. The component is built
 * to be flexible, accepting any React child elements.
 * The animations for the dropdown transitions are defined in `DropdownTransition`, which specifies
 * the behavior for the `animate`, `exit`, and `initial` states.
 *
 * @param props          The component props.
 * @param props.children The content to be displayed within the dropdown.
 * @returns A JSX element that renders a styled, animated dropdown containing the provided child elements.
 *
 * @example
 * ```tsx
 * <Dropdown>
 *   <P>Item 1</P>
 *   <div>Item 2</div>
 * </Dropdown>
 * ```
 */
export const Dropdown = ({children}: WithChildren) => (
    <StyledDropdown
        animate="animate"
        exit="exit"
        initial="initial"
        variants={DropdownTransition}
    >
        {children}
    </StyledDropdown>
);

const StyledDropdown = styled(motion.div)`
    background-color: ${({theme}) => theme.colors.ultraDropdownBgColor};
    border-radius: .8rem;
    left: auto;
    padding: .5rem 1.8rem 1rem 1.8rem;
    position: absolute;
    right: 0;
    width: 100%;
`;