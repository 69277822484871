import {Col, media, Row, Spacer, spacing, translucify} from '@nfq/react-grid';
import Image from 'next/image';
import Link from 'next/link';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {SROnly} from 'UI/components/layout/A11y';

import {Facebook} from 'Images/icons/ultra/Facebook';
import {Instagram} from 'Images/icons/ultra/Instagram';
import {LinkedIn} from 'Images/icons/ultra/LinkedIn';
import {PassionOfCyclingLogo} from 'Images/ultra';

/**
 * The UltraFooter component.
 *
 * @returns A React element representing the `UltraFooter` component.
 */
export const UltraFooter = () => {
    const {t} = useTranslation('common', {keyPrefix: 'footer'});

    return (
        <Wrapper>
            <StyledRow align="center" direction={{md: 'row', xs: 'column'}}>
                <Col direction="row" justify={{md: 'flex-start', xs: 'center'}}>
                    <LogoWrapper>
                        <StyledImage alt="Corratec Ultra - Passion of cycling" src={PassionOfCyclingLogo} />
                        <SROnly>corratec Ultra</SROnly>
                    </LogoWrapper>
                </Col>
                <Spacer y={8} />
                <Col direction="row" justify="center">
                    <StyledLink href={t('socialNetworks.instagram')} target="_blank">
                        <Instagram height={44} width={44} />
                    </StyledLink>
                    <StyledLink href={t('socialNetworks.facebook')} target="_blank">
                        <Facebook height={44} width={44} />
                    </StyledLink>
                    <StyledLink href={t('socialNetworks.linkedin')} target="_blank">
                        <LinkedIn height={44} width={44} />
                    </StyledLink>
                </Col>
                <Spacer y={8} />
                <Col direction="row" justify={{md: 'flex-end', xs: 'center'}}>
                    <FooterLink href={t('conditionsUrl')} target="_blank">{t('conditions')}</FooterLink>
                    <Spacer maxX={4} x={4} />
                    <FooterLink href={t('imprintUrl')} target="_blank">{t('imprint')}</FooterLink>
                    <Spacer maxX={4} x={4} />
                    <FooterLink href={t('privacyUrl')} target="_blank">{t('privacy')}</FooterLink>
                </Col>
            </StyledRow>
        </Wrapper>
    );
};

const Wrapper = styled.footer`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 171.2rem;
    padding: 0 ${spacing(4)};
    width: 100%;

    ${media('md')} {
        padding: 0 ${spacing(15)};
    }

    ${media('xl')} {
        max-width: 180rem;
    }
`;

const StyledLink = styled(Link)`
    border: .1rem transparent solid;
    border-radius: .8rem;
    outline: 0;

    &:hover {
        opacity: .6;
    }

    &:focus-visible {
        border-color: ${({theme}) => theme.colors.ultraFocusColor};
    }
`;

const FooterLink = styled(StyledLink)`
    color: ${({theme}) => translucify(theme.colors.ultraPageForeground, 100)};
    font: 400 1.4rem / 1.3 ${({theme}) => theme.fonts.Dinot};
    padding: .8rem;
    text-decoration: none;
`;

const StyledRow = styled(Row)`
    border-top: .1rem solid ${({theme}) => translucify(theme.colors.ultraPageForeground, 30)};
    gap: 0;
    padding: ${spacing(10)} 0;
`;

const LogoWrapper = styled.h1`
    margin: 0;
`;

const StyledImage = styled(Image)`
    width: 21.5rem;

    ${media('lg')} {
        width: 28.7rem;
    }
`;