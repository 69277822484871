import type {MouseEvent as ReactMouseEvent} from 'react';

import {darken, media, spacing, translucify} from '@nfq/react-grid';
import Link from 'next/link';
import styled from 'styled-components';

import type {WithChildren} from 'types/global';

interface BaseProps<C extends 'button' | 'link'> {
    as: C;
    className?: string;
    disabled?: boolean;
    size?: 'normal' | 'small';
    variant?: 'primary' | 'secondary';
    width?: 'auto' | 'full';
}

type ButtonProps<C extends 'button' | 'link'> = BaseProps<C> & (C extends 'button' ? {
    form?: string;
    href?: never;
    onClick?(e: ReactMouseEvent): void;
    target?: never;
    type: 'button' | 'reset' | 'submit';
} : C extends 'link' ? {
    form?: never;
    href: string;
    onClick?(e: ReactMouseEvent): void;
    target?: '_blank' | '_parent' | '_self' | '_top';
    type?: never;
} : never);

/**
 * The button component for the EVO CCT Ultra landing page.
 *
 * @param props           The component props.
 * @param props.as        Specifies whether the component should behave as a 'button' or 'link'.
 * @param props.children  The component children.
 * @param props.className The class name for the component. This is used to customize the styling of the component.
 * @param props.href      The URL to navigate to when the component is used as a link. Only applicable for 'link' type.
 * @param props.variant   The button variant, either 'primary' or 'secondary'.
 * @param props.width     The width of the rendered component, can either be 'auto' or 'full'. The default is 'auto'.
 * @param props.size      The overall size of the component, can either be 'normal' or 'small'. The default is 'normal'.
 * @param props.onClick   The function to call when the component is clicked.
 * @param props.target    The target window to open the link in. Only applicable for 'link' type.
 * @param props.disabled  Indicating whether the button is disabled or not.
 * @param props.form      The form the button belongs to.
 * @returns The rendered button or link component.
 */
export const Button = <C extends 'button' | 'link' = 'button'>({
    as,
    children,
    className,
    disabled,
    form,
    href,
    onClick,
    size = 'normal',
    target,
    variant = 'primary',
    width = 'auto'
}: WithChildren<ButtonProps<C>>) => (
    <Wrapper
        $size={size}
        $variant={variant}
        $width={width}
        as={as === 'link' ? Link : 'button'}
        className={className}
        disabled={disabled}
        form={form}
        href={href}
        target={target}
        onClick={onClick}
    >
        {children}
    </Wrapper>
    );

Button.displayName = 'Button';

type ButtonWrapperProps = {
    $size: 'normal' | 'small';
    $variant: 'primary' | 'secondary';
    $width: 'auto' | 'full';
};

const Wrapper = styled.button<ButtonWrapperProps>`
    align-items: center;
    background: ${({$variant, theme}) => ($variant === 'primary'
        ? theme.colors.ultraSecondaryColor
        : translucify(theme.colors.ultraPageForeground, 10))};
    border: 1px transparent solid;
    border-radius: calc(infinity * 1px);
    color: ${({$variant, theme}) => ($variant === 'primary'
        ? theme.colors.ultraPageBackground
        : theme.colors.ultraPageForeground)};
    cursor: pointer;
    display: inline-flex;
    font-family: ${({theme}) => theme.fonts.Dinot};
    font-size: ${({$size}) => ($size === 'small' ? '1.2rem' : '1.6rem')};
    font-weight: 700;
    gap: ${spacing(1.5)};
    justify-content: center;
    line-height: 1;
    margin: 0;
    outline: 0 none;
    padding: ${({$size, theme}) => {
        if ($size === 'small') {
            return `${spacing(2, theme)} ${spacing(4, theme)}`;
        }
        return `${spacing(3, theme)} ${spacing(8, theme)}`;
    }};
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 180ms ease-in-out;
    width: ${({$width}) => ($width === 'full' ? '100%' : 'auto')};

    &:hover, &:focus-visible {
        background: ${({$variant, theme}) => ($variant === 'primary'
        ? darken(theme.colors.ultraSecondaryColor, 11)
        : translucify(theme.colors.ultraPageForeground, 20))};
    }

    &:focus-visible {
        border-color: ${({theme}) => theme.colors.ultraFocusColor};
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background: ${({$variant, theme}) => ($variant === 'primary'
        ? theme.colors.ultraSecondaryColor
        : translucify(theme.colors.ultraPageForeground, 10))};
    }

    ${media('md')} {
        font-size: ${({$size}) => ($size === 'small' ? '1.4rem' : '2rem')};
        padding: ${({$size, theme}) => {
        if ($size === 'small') {
            return `${spacing(3, theme)} ${spacing(4, theme)}`;
        }
        return `${spacing(5, theme)} ${spacing(8, theme)}`;
    }};
    }
`;