/* eslint-disable max-len */
import React from 'react';

interface LinkedInProps {
    /**
     * A string representing the CSS class to be applied to the LinkedInIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the LinkedInIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the LinkedInIcon element.
     */
    width?: number | string;
}

/**
 * The `LinkedIn` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `LinkedInProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the LinkedInIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the LinkedInIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the LinkedInIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const LinkedInComponent = <LinkedIn className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const LinkedIn = React.forwardRef<SVGSVGElement, LinkedInProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 45 45"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <circle cx="22.5" cy="22.6567" r="15.2381" stroke={color1} strokeWidth="1.52381" />
            <path d="M25.5 19.8286C26.4946 19.8286 27.4484 20.2237 28.1517 20.927C28.8549 21.6302 29.25 22.5841 29.25 23.5786V27.9536H26.75V23.5786C26.75 23.2471 26.6183 22.9292 26.3839 22.6947C26.1495 22.4603 25.8315 22.3286 25.5 22.3286C25.1685 22.3286 24.8505 22.4603 24.6161 22.6947C24.3817 22.9292 24.25 23.2471 24.25 23.5786V27.9536H21.75V23.5786C21.75 22.5841 22.1451 21.6302 22.8483 20.927C23.5516 20.2237 24.5054 19.8286 25.5 19.8286Z" fill={color1} />
            <path d="M19.25 20.4536H16.75V27.9536H19.25V20.4536Z" fill={color1} />
            <path d="M18 18.5786C18.6904 18.5786 19.25 18.019 19.25 17.3286C19.25 16.6383 18.6904 16.0786 18 16.0786C17.3096 16.0786 16.75 16.6383 16.75 17.3286C16.75 18.019 17.3096 18.5786 18 18.5786Z" fill={color1} />
        </svg>
    );
});

LinkedIn.displayName = 'LinkedIn';
LinkedIn.defaultProps = {
    className: '',
    color1: '#fff',
    height: 45,
    testId: 'LinkedIn',
    width: 45
};