import {useEffect, useState} from 'react';

/**
 * Custom React hook to track the direction of the user's scroll action within a web page.
 * It returns a boolean value indicating whether the user is scrolling up or not.
 *
 * @returns The direction of the user's scroll action.
 */
export const useScrollListener = () => {
    const [prevScrollPos, setPrevScrollPos] = useState<number>(0);
    const [isScrollingUp, setIsScrollingUp] = useState<boolean>(false);

    useEffect(() => {
        /**
         * `handleScroll` is a function designed to determine the direction of the user's scroll action within
         * a web page. Upon each invocation, triggered by a scroll event, it captures the current vertical scroll
         * position (`window.scrollY`) and compares it with the previous scroll position stored in state
         * (`prevScrollPos`).
         * If the current scroll position is less than the previous one, it indicates that
         * the user is scrolling up. Based on this comparison, the function updates the `isScrollingUp` state
         * to `true` if the user is scrolling up, and to `false` otherwise.
         * Additionally, it updates the `prevScrollPos` state with the current scroll position to prepare for the next scroll event.
         *
         * @example
         * ```ts
         * window.addEventListener('scroll', handleScroll);
         * ```
         */
        const handleScroll = () => {
            // eslint-disable-next-line react-hooks-ssr/react-hooks-global-ssr
            const currentScrollPos = window.scrollY;

            const scrollingUp = prevScrollPos > currentScrollPos;

            scrollingUp ? setIsScrollingUp(true) : setIsScrollingUp(false);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    return {isScrollingUp};
};