import {Col, media, Row, spacing} from '@nfq/react-grid';
import Link from 'next/link';
import styled from 'styled-components';

import {LanguageSwitch} from 'UI/components/action/ultra/LanguageSwitch/LanguageSwitch';
import {SROnly} from 'UI/components/layout/A11y';

import {useScrollListener} from 'UI/hooks/useScrollListener';

import {CorratecUltraLogo} from 'Images/icons/ultra';

/**
 * The `UltraHeader` component is the header for the CCT EVO Ultra campaign landing page. It contains the logo and
 * language switch components.
 *
 * @returns The JSX element for the `UltraHeader` component.
 */
export const UltraHeader = () => {
    const {isScrollingUp} = useScrollListener();

    return (
        <Wrapper $isScrollingUp={isScrollingUp}>
            <StyledRow>
                <Col align="center" direction="row" justify="space-between">
                    <LogoWrapper>
                        <Link href="/">
                            <Logo />
                        </Link>
                        <SROnly>corratec Ultra</SROnly>
                    </LogoWrapper>
                    <LanguageSwitch />
                </Col>
            </StyledRow>
        </Wrapper>
    );
};

const Wrapper = styled.header<{$isScrollingUp: boolean}>`
    align-items: center;
    background-color: ${({theme}) => theme.colors.ultraHeaderBackgroundColor};
    display: flex;
    height: 4.8rem;
    justify-content: center;
    position: sticky;
    top: ${({$isScrollingUp}) => ($isScrollingUp ? '0' : '-4.8rem')};
    transition: top .25s ease-in-out;
    z-index: 21;

    ${media('md')} {
        height: 6.2rem;
        top: ${({$isScrollingUp}) => ($isScrollingUp ? '0' : '-6.2rem')};
    }
`;

const StyledRow = styled(Row)`
    max-width: 171.2rem;
    padding: 0 1.6rem;

    ${media('xl')} {
        max-width: 180rem;
        padding: 0 ${spacing(15)};
    }
`;

const LogoWrapper = styled.div`
    position: relative;
`;

const Logo = styled(CorratecUltraLogo)`
    display: block;
    height: auto;
    width: 12rem;

    ${media('md')} {
        width: 17.2rem;
    }
`;