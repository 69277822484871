import {media, translucify} from '@nfq/react-grid';
import styled from 'styled-components';

import {getDesktopFontSize, getFontSize, getLineHeight} from 'UI/components/layout/ultra/Text/utils';

interface TextProps {
    $align?: 'center' | 'end' | 'start';
    $size?: 'lg' | 'md' | 'sm' | 'xs';
}

export const Text = styled.p<TextProps>`
    color: ${({theme}) => translucify(theme.colors.ultraPageForeground, 50)};
    font-family: ${({theme}) => theme.fonts.Dinot};
    font-size: ${getFontSize};
    font-weight: 400;
    line-height: ${getLineHeight};
    margin: 0;
    text-align: ${({$align}) => $align ?? 'inherit' as const};

    ${media('lg')} {
        font-size: ${getDesktopFontSize};
        line-height: 1.4;
    }
`;

export const TextHighlight = styled.em`
    color: ${({theme}) => theme.colors.ultraPageForeground};
    font: inherit;
    font-style: normal;
    font-weight: 700;
`;