/* eslint-disable max-len */
import React from 'react';

interface FacebookProps {
    /**
     * A string representing the CSS class to be applied to the FacebookIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the FacebookIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the FacebookIcon element.
     */
    width?: number | string;
}

/**
 * The `Facebook` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `FacebookProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the FacebookIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the FacebookIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the FacebookIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const FacebookComponent = <Facebook className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Facebook = React.forwardRef<SVGSVGElement, FacebookProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 45 45"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <circle cx="22.5" cy="22.6567" r="15.2381" stroke={color1} strokeWidth="1.52381" />
            <path clipRule="evenodd" d="M23.8198 30.6567H20.2725V22.6557H18.5V19.8988H20.2725V18.2437C20.2725 15.9948 21.2729 14.6567 24.1167 14.6567H26.4837V17.4146H25.0044C23.8973 17.4146 23.8241 17.7999 23.8241 18.5189L23.8192 19.8988H26.5L26.1862 22.6557H23.8192V30.6567H23.8198Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Facebook.displayName = 'Facebook';
Facebook.defaultProps = {
    className: '',
    color1: '#fff',
    height: 45,
    testId: 'Facebook',
    width: 45
};