import {Col, Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {Input} from 'UI/components/action/ultra/Input';
import {P} from 'UI/components/layout/Text';

import {useSubmitSubscriptionForm} from 'UI/modules/ultra/Subscription/useSubmitSubscriptionForm';

/**
 * The NewsletterForm component.
 * It is used to subscribe to the newsletter. It contains the form with its input, submit button and error text.
 * The form is used to submit the email address to the newsletter.
 *
 * @param props           The component props.
 * @param props.onSuccess Callback which gets executed once the subscription was successful.
 * @returns The form or the success component.
 */
export const NewsletterForm = ({onSuccess}: {onSuccess(): void}) => {
    const {t} = useTranslation('ultra', {keyPrefix: 'teaser'});
    const {error, handleSubmitNotifyForm} = useSubmitSubscriptionForm(onSuccess);
    const hasError = Boolean(error);
    const errorMessageKey = error ? error.info : undefined;

    return (
        <Form onSubmit={handleSubmitNotifyForm}>
            <Col align={{lg: 'flex-start', xs: 'center'}}>
                <Wrapper>
                    <Input
                        name="email"
                        placeholder={t('newsletterPlaceholder')}
                        submitLabel={t('newsletterSubscribe')}
                        type="email"
                    />
                </Wrapper>
                <Spacer y={1} />
                {(hasError && errorMessageKey) && (
                    <>
                        <Spacer y={2} />
                        <InputError id="email-error">{t(errorMessageKey)}</InputError>
                    </>
                )}
            </Col>
        </Form>
    );
};

const Form = styled(motion.form)`
    display: block;
    width: 100%;
`;

const Wrapper = styled.div`
    align-items: center;
    display: grid;
    position: relative;
    width: 100%;
`;

const InputError = styled(P)`
    color: ${({theme}) => theme.colors.ultraDangerColor};
    font: 1.2rem / 1.5 ${({theme}) => theme.fonts.Dinot};
`;