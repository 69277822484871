import {spacing} from '@nfq/react-grid';
import styled from 'styled-components';

import {Button} from 'UI/components/action/ultra/Button';
import {Dropdown} from 'UI/components/layout/ultra/Dropdown';

import {useLanguageSwitch} from './useLanguageSwitch';
import {Chevron} from 'Images/icons/ultra/Chevron';

import type {availableLanguagesType} from './useLanguageSwitch';

/**
 * The `LanguageSwitch` component is a button that displays the currently selected language and allows the user to
 * switch between the available languages. It uses the `useLanguageSwitch` hook to manage the state of the language
 * dropdown menu and the currently selected language.
 *
 * @returns The JSX element for the `LanguageSwitch` component.
 */
export const LanguageSwitch = () => {
    const {
        availableLanguages,
        changeLanguage,
        dropdownIsShowing,
        selectedLanguage,
        toggleLanguageDropdown
    } = useLanguageSwitch();

    return (
        <DropdownWrapper>
            <StyledButton
                size="small"
                type="button"
                variant="secondary"
                width="auto"
                onClick={toggleLanguageDropdown}
            >
                <selectedLanguage.icon />
                <span>{selectedLanguage.key}</span>
                <IconWrapper $rotate={dropdownIsShowing}>
                    <Chevron height={8} />
                </IconWrapper>
            </StyledButton>
            {dropdownIsShowing && (
                <Dropdown>
                    {availableLanguages.map(language => (
                        <StyledButton
                            key={language.id}
                            size="small"
                            type="button"
                            variant="secondary"
                            width="auto"
                            onClick={async () => changeLanguage(language.id as availableLanguagesType)}
                        >
                            <language.icon />
                            <span>{language.key}</span>
                        </StyledButton>
                    ))}
                </Dropdown>
            )}
        </DropdownWrapper>
    );
};

const DropdownWrapper = styled.div`
    padding-left: 1.8rem;
    position: relative;
`;

interface IconWrapperProps {
    $rotate: boolean;
}

const IconWrapper = styled.span<IconWrapperProps>`
    align-items: center;
    display: flex;
    transform: rotate(${({$rotate}) => ($rotate ? '0' : '180deg')});
    transition: transform .15s ease-in;
`;

const StyledButton = styled(Button)`
    background-color: ${({theme}) => theme.colors.ultraHeaderButtonBgColor};
    border-radius: 0.8rem;
    justify-content: flex-start;
    padding: ${spacing(0.5)};

    &:hover {
        background-color: ${({theme}) => theme.colors.ultraHeaderButtonBgColor};

        span {
            opacity: .8;
        }
    }

    &:focus {
        background-color: ${({theme}) => theme.colors.ultraHeaderButtonBgColor};
        border-color: transparent;
    }

    &:focus-visible {
        border-color: ${({theme}) => theme.colors.ultraFocusColor};
    }
`;