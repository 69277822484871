import {media} from '@nfq/react-grid';
import styled from 'styled-components';

import {H1} from 'UI/components/layout/Text';

import {getDesktopFontSize, getFontSize, getLineHeight} from './utils';

export interface HeadlineProps {
    $align?: 'center' | 'start';
    $size?: 'lg' | 'md' | 'sm' | 'xs';
}

export const Headline = styled(H1)<HeadlineProps>`
    color: ${({theme}) => theme.colors.ultraPageForeground};
    font-family: ${({theme}) => theme.fonts.Dinot};
    font-size: ${getFontSize};
    font-weight: 700;
    line-height: ${getLineHeight};
    text-align: ${({$align}) => $align ?? 'inherit' as const};
    text-transform: uppercase;

    ${media('lg')} {
        font-size: ${getDesktopFontSize};
    }
`;